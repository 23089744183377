<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-lx-cascades"></i> 基本配置</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="form-box">
                <el-form ref="form" :model="form" label-width="150px">
                    <el-form-item label="是否开启活动">
                        <el-switch v-model="form.status"></el-switch>
                    </el-form-item>
                    <el-form-item label="活动主题：">
                        <el-input v-model="form.title" placeholder="请输入活动主题"></el-input>
                    </el-form-item>
                    <el-form-item label="活动副标题：">
                        <el-input v-model="form.subtitle" placeholder="请输入活动副标题"></el-input>
                    </el-form-item>
                    <el-form-item label="最多可选数量：">
                        <el-input v-model="form.num" placeholder="请输入最多可选数量"></el-input>
                    </el-form-item>
                    <el-form-item label="总价格：">
                        <el-input v-model="form.money" placeholder="请输入总价格"></el-input>
                    </el-form-item>
                    <el-form-item label="活动开始日期">
                        <el-date-picker
                                v-model="form.startdate"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="活动截止日期">
                        <el-date-picker
                                v-model="form.enddate"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="标题图片">
                        <el-upload
                                class="avatar-uploader"
                                name="image"
                                with-credentials
                                :data="{id:this.form.titleimg}"
                                :action="uploadUrl()"
                                :on-error="uploadError"
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload"
                                :on-progress="uploading"
                                :show-file-list="false"
                                :auto-upload="true"
                                enctype="multipart/form-data">
                            <img v-if="form.titleimgUrl" :src="form.titleimgUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="背景图片">
                        <el-upload
                                class="avatar-uploader"
                                name="image"
                                with-credentials
                                :data="{id:this.form.backimg}"
                                :action="uploadUrl()"
                                :on-error="uploadError"
                                :on-success="handleAvatarSuccess2"
                                :before-upload="beforeAvatarUpload"
                                :on-progress="uploading"
                                :show-file-list="false"
                                :auto-upload="true"
                                enctype="multipart/form-data">
                            <img v-if="form.backimgUrl" :src="form.backimgUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="咨询分享码背景图片">
                        <el-upload
                                class="avatar-uploader"
                                name="image"
                                with-credentials
                                :data="{id:this.form.qrcode_backimg}"
                                :action="uploadUrl()"
                                :on-error="uploadError"
                                :on-success="handleAvatarSuccess3"
                                :before-upload="beforeAvatarUpload"
                                :on-progress="uploading"
                                :show-file-list="false"
                                :auto-upload="true"
                                enctype="multipart/form-data">
                            <img v-if="form.qrcode_backimg_url" :src="form.qrcode_backimg_url" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">确定</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>

    </div>
</template>

<script>
    import 'quill/dist/quill.core.css';
    import 'quill/dist/quill.snow.css';
    import 'quill/dist/quill.bubble.css';
    import {quillEditor, Quill} from 'vue-quill-editor'
    import {container, ImageExtend, QuillWatch} from 'quill-image-extend-module';
    Quill.register('modules/ImageExtend', ImageExtend)
    export default {
        name: 'baseform',
        components: {
            quillEditor
        },
        data: function(){
            return {
                form:{
                    id: '',
                    title: '',
                    subtitle: '',
                    status: '',
                    num: '',
                    money: '',
                    startdate: '',
                    enddate: '',
                    titleimg: '',
                    titleimgUrl: '',
                    backimg: '',
                    backimgUrl: '',
                    qrcode_backimg: '',
                    qrcode_backimg_url: '',
                },
                loading:false,
                // 富文本框参数设置
                editorOption: {
                    modules: {
                        ImageExtend: {
                            loading: true,
                            name: 'image',
                            action: this.$api.uploadUrl+"/Images/uploadEditorImage",
                            response: (res) => {
                                return res.data
                            }
                        },
                        toolbar: {
                            container: container,
                            handlers: {
                                'image': function () {
                                    QuillWatch.emit(this.quill.id)
                                }
                            }
                        }
                    }
                },
            }
        },
        created() {
            this.getData();
        },
        methods: {
            onEditorChange({ editor, html, text }) {
                this.form.details = html;
            },
            //设置上传图片接口地址
            uploadUrl(){
                var url=this.$api.uploadUrl + "/Images/upload";
                return url
            },
            //图片上传之前
            beforeAvatarUpload(file){
                console.log(file);
                this.loading=true;
            },
            //正在上传中
            uploading(event, file, fileList){
                // console.log(event);
                // console.log(file);
                // console.log(fileList);
            },
            //图片上传失败
            uploadError(err){
                this.$message.error(err.msg);
            },
            //周年庆图片上传成功
            handleAvatarSuccess(res, file){
                console.log(res);
                this.form.titleimg=res.data;
                this.form.titleimgUrl = URL.createObjectURL(file.raw);
                this.$message.success(res.msg);
            },
            //品牌内容图片上传成功
            handleAvatarSuccess2(res, file){
                this.loading=false;
                // console.log(res);
                this.form.backimg=res.data;
                this.form.backimgUrl = URL.createObjectURL(file.raw);
                this.$message.success(res.msg);
            },
            //分享码背景图片上传成功
            handleAvatarSuccess3(res, file){
                this.loading=false;
                console.log(res);
                this.form.qrcode_backimg=res.data;
                this.form.qrcode_backimg_url = URL.createObjectURL(file.raw);
                this.$message.success(res.msg);
            },
            //获取初始数据
            getData() {
                //不传参数要写null
                this.$api.post('FortuneBagConfig/getConfig', null, res => {
                    this.form = res.data;
                    console.log(this.form);
                }, err => {
                    this.tableData = [];
                    this.$message.error(err.msg);
                });
            },
            onSubmit() {
                var params=this.$qs.stringify({
                    id: this.form.id,
                    title: this.form.title,
                    subtitle: this.form.subtitle,
                    status: this.form.status ? 1 : 0,
                    num: this.form.num,
                    money: this.form.money,
                    startdate: this.form.startdate,
                    enddate: this.form.enddate,
                    titleimg: this.form.titleimg,
                    backimg: this.form.backimg,
                    qrcode_backimg: this.form.qrcode_backimg,
                });
                console.log({
                    id: this.form.id,
                    title: this.form.title,
                    subtitle: this.form.subtitle,
                    status: this.form.status ? 1 : 0,
                    num: this.form.num,
                    money: this.form.money,
                    startdate: this.form.startdate,
                    enddate: this.form.enddate,
                    titleimg: this.form.titleimg,
                    backimg: this.form.backimg,
                    qrcode_backimg: this.form.qrcode_backimg,
                });
                // return;
                this.$api.post('FortuneBagConfig/saveConfig', params, res => {
                    this.$message.success(res.msg);
                }, err => {
                    this.tableData = [];
                    this.$message.error(err.msg);
                });
            },
            //将转移符号替换为html
            escapeStringHTML(str) {
                if(str){
                    str = str.replace(/&lt;/g,'<');
                    str = str.replace(/&gt;/g,'>');
                    str = str.replace(/&quot;/g,'"');
                }
                return str;
            },
            submit(){
                let str=this.escapeStringHTML(this.form.details);
                // console.log(str);
            },
        }
    }
</script>

<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 100%;
        /*height: 100%;*/
        display: block;
    }
    .el-upload--picture-card{
        width: 100% !important;
        height: auto !important;
    }
</style>
